'use client';
import useProduct from '@/contexts/ProductProvider';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import styles from './hamburger.module.scss';

const Hamburger: React.FC<any> = () => {
	const pathname = usePathname();
	const { showHamburger, setShowHamburger } = useProduct();
	const [windowShow, setWindowShow] = useState<any>(0);

	useEffect(() => {
		const handleResize = () => {
			setWindowShow(window.innerWidth >= 1000 ? false : true);
			setShowHamburger(window.innerWidth >= 1000 ? true : false);
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleHamburger = () => {
		setShowHamburger(!showHamburger);
	};

	return (
		<>
			{pathname.includes('/jobs') !== true && pathname.includes('/blog') !== true && windowShow ? (
				showHamburger ? (
					<div
						onClick={handleHamburger}
						className={styles.show_hamburger}
						style={{
							marginRight: '10px',
							marginBottom: '12px',
							height: '25px',
							width: '25px',
							display: 'flex',
							justifyContent: 'center'
						}}>
						<svg
							width='18'
							height='18'
							viewBox='0 0 18 18'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
							style={{
								height: '18px',
								width: '18px'
							}}>
							<path
								d='M9.00004 10.9419L2.97221 16.9697C2.70371 17.2382 2.37964 17.3725 2 17.3725C1.62039 17.3725 1.29632 17.2382 1.02779 16.9697C0.759264 16.7012 0.625 16.3771 0.625 15.9975C0.625 15.6178 0.759264 15.2938 1.02779 15.0253L7.05563 8.99744L1.02779 3.01127C0.759264 2.74277 0.625 2.4187 0.625 2.03906C0.625 1.65945 0.759264 1.33538 1.02779 1.06685C1.29632 0.798326 1.62039 0.664062 2 0.664062C2.37964 0.664062 2.70371 0.798326 2.97221 1.06685L9.00004 7.09469L14.9862 1.06685C15.2362 0.798326 15.5556 0.664062 15.9445 0.664062C16.3334 0.664062 16.6621 0.798326 16.9306 1.06685C17.2084 1.34463 17.3473 1.67102 17.3473 2.04602C17.3473 2.42102 17.2084 2.74277 16.9306 3.01127L10.9028 8.99744L16.9306 15.0253C17.1992 15.2938 17.3334 15.6178 17.3334 15.9975C17.3334 16.3771 17.1992 16.7012 16.9306 16.9697C16.6528 17.2475 16.3265 17.3864 15.9515 17.3864C15.5765 17.3864 15.2547 17.2475 14.9862 16.9697L9.00004 10.9419Z'
								fill='#718096'
							/>
						</svg>
					</div>
				) : (
					<div
						onClick={handleHamburger}
						className={styles.show_hamburger}
						style={{
							marginRight: '10px',
							marginBottom: '12px'
						}}>
						<svg
							width='40'
							height='40'
							viewBox='0 0 40 40'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
							style={{
								height: '25px',
								width: '25px'
							}}>
							<mask
								id='mask0_4224_3622'
								style={{ maskType: 'alpha' }}
								maskUnits='userSpaceOnUse'
								x='0'
								y='0'
								width='40'
								height='40'>
								<rect width='40' height='40' fill='#D9D9D9' />
							</mask>
							<g mask='url(#mask0_4224_3622)'>
								<path
									d='M6.38887 30C5.99537 30 5.66551 29.8664 5.39929 29.5993C5.1331 29.3322 5 29.0011 5 28.6063C5 28.2114 5.1331 27.882 5.39929 27.6181C5.66551 27.3542 5.99537 27.2223 6.38887 27.2223H33.6111C34.0046 27.2223 34.3345 27.3558 34.6007 27.623C34.8669 27.8901 35 28.2211 35 28.616C35 29.0109 34.8669 29.3403 34.6007 29.6042C34.3345 29.8681 34.0046 30 33.6111 30H6.38887ZM6.38887 21.3889C5.99537 21.3889 5.66551 21.2553 5.39929 20.9882C5.1331 20.721 5 20.39 5 19.9951C5 19.6002 5.1331 19.2708 5.39929 19.007C5.66551 18.7431 5.99537 18.6111 6.38887 18.6111H33.6111C34.0046 18.6111 34.3345 18.7447 34.6007 19.0118C34.8669 19.279 35 19.61 35 20.0049C35 20.3998 34.8669 20.7292 34.6007 20.993C34.3345 21.2569 34.0046 21.3889 33.6111 21.3889H6.38887ZM6.38887 12.7778C5.99537 12.7778 5.66551 12.6442 5.39929 12.377C5.1331 12.1099 5 11.7789 5 11.384C5 10.9891 5.1331 10.6597 5.39929 10.3958C5.66551 10.1319 5.99537 10 6.38887 10H33.6111C34.0046 10 34.3345 10.1336 34.6007 10.4007C34.8669 10.6678 35 10.9989 35 11.3938C35 11.7886 34.8669 12.118 34.6007 12.3819C34.3345 12.6458 34.0046 12.7778 33.6111 12.7778H6.38887Z'
									fill='#718096'
								/>
							</g>
						</svg>
					</div>
				)
			) : (
				''
			)}
		</>
	);
};

export default Hamburger;

'use client';
import { usePathname } from 'next/navigation';
import { BiSolidDashboard } from 'react-icons/bi';

const ShopByCategoriesButton = ({ className }) => {
	const pathname = usePathname();
	const shouldShowHeader = !pathname.startsWith('/blog') && !pathname.startsWith('/jobs');
	if (!shouldShowHeader) {
		return null;
	}
	return (
		<div className={className}>
			<BiSolidDashboard size={26} />
			<span>Shop By Category</span>
		</div>
	);
};

export default ShopByCategoriesButton;

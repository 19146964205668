'use client';
import useAuth from '@/contexts/AuthProvider';
import useLoginModel from '@/contexts/LoginModelProvider';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { Fragment } from 'react';
import { FaUser } from 'react-icons/fa6';
import styles from './HeaderUserInfo.module.scss';
const HeaderUserInfo = (className) => {
	const { user, isLogged }: any = useAuth();
	const { setShowLoginModal } = useLoginModel();
	const router = useRouter();

	return (
		<div
			className={styles.top__right__user}
			onClick={() => {
				isLogged ? router.push('/account') : setShowLoginModal(true);
			}}>
			<Fragment>
				<div
					className={styles.top__right__user__icon}
					style={{
						position: 'relative'
					}}>
					{user?.u_pic_url ? (
						<Image
							src={user?.u_pic_url}
							style={{
								width: '40px',
								height: '40px',
								borderRadius: '50%',
								backgroundColor: '#f1f1f1'
							}}
							width={40}
							height={40}
							alt='Arogga Logo'
							unoptimized
						/>
					) : (
						<div
							style={{
								width: '40px',
								height: '40px',
								backgroundColor: '#EEEFF2',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: '50%'
							}}>
							<FaUser size={20} className='text-100 text-grey500 ' />
						</div>
					)}
					{user?.b2b_status?.status === 'approved' && (
						<div
							style={{
								position: 'absolute',
								fontSize: '8px',
								backgroundColor: '#FD6A6A',
								borderRadius: '3px',
								color: '#fff',
								padding: '2px 5px',
								bottom: 0,
								left: '-4px'
							}}>
							WholeSale
						</div>
					)}
				</div>
				<div className='ml-10'>
					<div className={styles.top__right__user__info__name}>
						<span className={styles.username_text}>
							Hello, <span className='text-grey700 fw-600'>{user && user?.u_name ? user?.u_name : 'User'}</span>
						</span>
					</div>
					<div className={styles.top__right__user__info__balance}>
						{isLogged ? (
							<>
								<span className='mr-5'>Arogga Cash:</span>{' '}
								<span className='text-orange text-14'>৳{user?.u_cash || 0}</span>
							</>
						) : (
							<span className='text-grey700 text-14 fw-600'>Account & Orders</span>
						)}
					</div>
				</div>
			</Fragment>
		</div>
	);
};

export default HeaderUserInfo;

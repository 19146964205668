import styles from './Label.module.css';

const Label = ({ label, required }) => {
	return (
		<label className={styles.input_wrap_label}>
			<p className={`${styles.label} ltbody_lg__medium`}>{label}</p>
			{required ? <span>*</span> : ''}
		</label>
	);
};

export default Label;

import Label from '../Label';
import styles from './input.module.scss';

const Input = ({ placeholder, label, required, variant, leftIcon, rightIcon, focus = true, ...rest }) => {
	return (
		<div className={styles.input_wrap}>
			{label && <Label label={label} required={required} />}
			<div className={styles.input_field}>
				{leftIcon && <span className={styles.leftIcon}>{leftIcon}</span>}
				<input
					className={`
						${styles.input} ${variant ? styles[variant] : ''} 
						${leftIcon ? styles.leftIconInput : ''} 
						${rightIcon ? styles.rightIconInput : ''}
						${focus ? styles.focus : styles.no_focus}
						`}
					{...rest}
					required={required}
					placeholder={placeholder}
				/>
				{rightIcon && <span className={styles.rightIcon}>{rightIcon}</span>}
			</div>
		</div>
	);
};

export default Input;

'use client';

import useAuth from '@/contexts/AuthProvider';
import IncomingCallCard from '@/doc-consultation/presentation/molecules/CallingCards/IncomingCallCard';
import StateFullModal from '@/labtest/presentation/atoms/Modal/StateFullModal';
import useToggle from '@/services/lab-test/hooks/use-toggle';
import { isEmptyObject } from '@/services/lab-test/utils/cmnHelper';
import React, { createContext, useContext, useEffect, useState } from 'react';
import useAgoraSignalling from './AgoraSignalingContext';

// interface LoginModelContextType {
// 	showLoginModal: boolean;
// 	setShowLoginModal: React.Dispatch<React.SetStateAction<boolean>>;
// 	setGoTo: React.Dispatch<React.SetStateAction<string | null>>;
// 	searchValue: string;
// 	setSearchValue: React.Dispatch<React.SetStateAction<string>>;
// }

const CallingModelContext = createContext<any>(null);

interface I_Props {
	children: React.ReactNode;
}

export const CallingModelProvider = ({ children }: I_Props) => {
	const [incomingCallModal, handleOpenIncomingCallModal, handleCloseIncomingCallModal] = useToggle();
	const {
		isPatientLoggedIn,
		messageCall,
		signalPatientLogin,
		signalLogout,
		acceptCallInvitation,
		declineCallInvitation,
		notAnsweringCall
	} = useAgoraSignalling();
	const { user } = useAuth();
	const [isPatientResponding, setIsPatientResponding] = useState(false);

	const callRingtone = () => {
		console.log('incomingCallModal:', incomingCallModal);
		// audioRef.current.play();
		// setModalOpen(true);
		handleOpenIncomingCallModal();
	};

	console.log('messageCall:', messageCall);
	useEffect(() => {
		if (
			!isEmptyObject(messageCall) &&
			messageCall?.message?.patient_uid === user?.u_id.toString() &&
			messageCall?.message?.type === 'call-invitation'
		) {
			callRingtone();
		}
	}, [messageCall]);

	useEffect(() => {
		let timeoutId;
		if (incomingCallModal) {
			timeoutId = setTimeout(() => {
				if (!isPatientResponding) {
					// audioRef.current.pause();
					// setModalOpen(false);
					notAnsweringCall(messageCall);
					handleCloseIncomingCallModal();
				}
			}, 30000); // 180000 milliseconds = 3 minutes
		}

		return () => {
			clearTimeout(timeoutId);
		};
	}, [incomingCallModal, isPatientResponding, messageCall]);

	// console.log('[Patient]: Event Callback', eventCall);
	console.log('[Patient]: Message Callback', messageCall);

	useEffect(() => {
		if (isPatientResponding) {
			// audioRef.current.pause();
			// setModalOpen(false);
			handleCloseIncomingCallModal();
		}
	}, [isPatientResponding]);

	return (
		<CallingModelContext.Provider
			value={{
				incomingCallModal,
				handleOpenIncomingCallModal,
				handleCloseIncomingCallModal
			}}>
			{children}
			{/* Patient Incoming Call Modal (Answer / Decline) */}
			{incomingCallModal && (
				<StateFullModal open={incomingCallModal}>
					<IncomingCallCard
						messageCall={messageCall}
						acceptCallInvitation={acceptCallInvitation}
						declineCallInvitation={declineCallInvitation}
						handleCloseIncomingCallModal={handleCloseIncomingCallModal}
						setIsPatientResponding={setIsPatientResponding}
						// audioRef={audioRef}
						// patientId={consultationDetails?.userId}
						patientId={user?.u_id.toString()}
						// data={consultationDetails}
					/>
				</StateFullModal>
			)}
		</CallingModelContext.Provider>
	);
};

// LoginModelContextType
export const useCallingModal = () => {
	const context = useContext(CallingModelContext);
	if (context === undefined) {
		throw new Error('useCallingModal must be used within a CallingModelProvider');
	}
	return context;
};

export default useCallingModal;

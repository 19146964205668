'use client';

import useCart from '@/contexts/CartProvider';
import Button from '@/labtest/presentation/atoms/Button';
import useLabTestPixalEvent from '@/services/event/useLabTestPixalEvent';
import useLabCart from '@labServices/context/CartContext';
import Spinner from '@labtest/presentation/atoms/Loaders/Spinners';
import Radio from '@labtest/presentation/atoms/Radio';
import TextField from '@labtest/presentation/atoms/TextField';
import Address from '@labtest/presentation/molecules/Address';
import SampleCollectionScheduleCard from '@labtest/presentation/molecules/SampleCollectionScheduleCard';
import Image from 'next/image';

import { cartStorageName, userStorageName } from '@/services/apis/config';
import { getSampleCollectionSchedule, onlinePaymentForOrder, placeOrder } from '@labServices/apis/lab-test-apis/order';
import { useAuthContext } from '@labServices/context';
import { isEmptyObject, popupWindow } from '@labServices/utils/cmnHelper';
import { OnlinePaymentMethods } from '@labServices/utils/data';
import { getItemFromLocalStorage, setItemInLocalStorage } from '@labServices/utils/session';
import { v1_checkout_payment_info_image } from '@labServices/utils/svg';
import { forwardRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './Checkout.module.css';

const Checkout = ({
	labCheckoutData,
	closeCheckoutModal,
	handleOpenNotFound,
	handleOpenOrderSuccessModal,
	setOrderDetails,
	setNotAvailableRes
}) => {
	const [checkoutData, setCheckoutData] = useState({
		cartUqid: '',
		additionalNotes: '',
		priceDiscount: '',
		promoDiscount: '0',
		subtotalAmount: '',
		collectionConveyance: '',
		hardCopyConveyance: '',
		discountAmount: '',
		totalAmount: '',
		paymentMethod: 'cod'
	});
	const { setcartTotalPriceItems } = useAuthContext();
	const { setCartData } = useLabCart();
	const { setDrawerOpen } = useCart();
	const [loading, setLoading] = useState(true);
	const [userAddress, setUserAddress] = useState(null);
	const [checked, setChecked] = useState('');
	const [btnDisabled, setBtnDisabled] = useState(false);
	const [dateTimeSchedules, setDateTimeSchedules] = useState({
		dates: [],
		times: []
	});

	const [selectedDateTime, setSelectedDateTime] = useState({
		date: '',
		time: ''
	});

	const { LabTestCheckoutEvent, LabTestOrderPurchaseEvent } = useLabTestPixalEvent();

	const handlePaymentmetyhodSelect = (e, online) => {
		setCheckoutData((prev) => ({
			...prev,
			paymentMethod: e?.target?.name || online
		}));
		setChecked(e?.target?.name || online);
	};

	const makeOrder = async () => {
		const user = getItemFromLocalStorage(userStorageName);
		if (!user && user.u_role == 'user') {
			toast('You cannot make order using this number!', { type: 'error' });
			return;
		}

		if (!selectedDateTime?.date && !selectedDateTime.time) {
			toast('Select Date & Time!', { type: 'error' });
			return;
		}

		const updatedCheckoutData = {
			...checkoutData,
			scheduleDate: selectedDateTime?.date,
			scheduleTimeUqid: selectedDateTime?.time
		};

		const data = await placeOrder(updatedCheckoutData);

		closeCheckoutModal();
		setDrawerOpen(false);

		if (!data.success) {
			setNotAvailableRes(data);
			handleOpenNotFound();
			return;
		}
		setBtnDisabled(true);
		setOrderDetails(data?.data);
		setcartTotalPriceItems({
			totalAmount: 0,
			itemCount: 0
		});
		// removeItemFromLocalStorage(cartStorageName);
		setItemInLocalStorage(cartStorageName, {});
		// updateCartAddressFunc();

		// Lab Test Order Purchase Event
		LabTestOrderPurchaseEvent(data?.data);

		if (data?.data?.paymentMethod === PaymentMethod.ONLINE) {
			let popupWindowRef;
			const res = await onlinePaymentForOrder(data?.data?.id);

			if (res?.success) {
				popupWindowRef = popupWindow(`${res?.data?.paymentUrl}`, 'Arogga Payment', window, 650, 700);
				setCartData({});
				monitorPopupWindow();
			}
			function monitorPopupWindow() {
				if (popupWindowRef && !popupWindowRef.closed) {
					setTimeout(monitorPopupWindow, 200);
				} else {
					handleOpenOrderSuccessModal();
				}
			}
			return;
		}
		setCartData({});
		setDrawerOpen(false);
		handleOpenOrderSuccessModal();
	};

	const fetchCheckoutData = async () => {
		setLoading(true);
		if (isEmptyObject(labCheckoutData)) {
			return;
		}
		const data = labCheckoutData?.data;
		const uLoc = data.userLocation;
		setUserAddress({
			location: data?.location,
			ul_location: uLoc.location,
			ul_name: uLoc.name,
			ul_mobile: uLoc.mobileNumber,
			ul_type: uLoc.type,
			ul_default: uLoc.isDefault
		});
		setCheckoutData((prev) => ({
			...prev,
			cartUqid: data.id,
			subtotalAmount: data.subtotalAmount,
			discountAmount: data.discountAmount,
			priceDiscount: data.discountAmount,
			labMaterialCharge: data.labMaterialCharge,
			roundingOff: data.roundingOff,

			// Condition values
			hardCopyConveyance:
				data?.isHardCopyRequired || data?.shouldApplyHardCopyConveyance ? data.hardCopyConveyance : 0,
			collectionConveyance: data?.shouldApplyHardCopyConveyance ? data.collectionConveyance : 0,
			totalAmount: data?.isHardCopyRequired ? data?.totalAmount : data?.totalAmountWithoutConveyance
		}));

		setLoading(false);
		// Lab Test Checkout Event
		LabTestCheckoutEvent(data);
	};

	const fetchDateTimeSchedules = async () => {
		setLoading(true);
		const res = await getSampleCollectionSchedule();
		if (res?.success) {
			setDateTimeSchedules({
				dates: [...res?.data],
				times: [...res?.data[0]?.times]
			});

			setSelectedDateTime((prev) => ({
				...prev,
				date: res?.data[0]?.dateValue,
				time: res?.data[0]?.times[0]?.id
			}));
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchCheckoutData();
		fetchDateTimeSchedules();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={styles.checkout}>
			<div className={styles.checkout_address}>
				<div className={styles.checkout_address_head}>
					<p className='ltbody_lg__bold ltGreyscale900'>Sample Collection Address</p>
				</div>

				{/* Sample Collection Address */}
				<div className={styles.checkout_address_text}>
					{loading && (
						<div className='p_20'>
							<Spinner size='sm' />
						</div>
					)}
					{!loading && userAddress && <Address data={userAddress} editable={false} />}
				</div>

				{/* Additional Information */}
				<div className={styles.textfeild}>
					<TextField
						value={checkoutData.additionalNotes}
						onChange={(e) =>
							setCheckoutData((prev) => ({
								...prev,
								additionalNotes: e.target.value
							}))
						}
						maxLength={120}
						placeholder='Additional Info (If any)'
						rows={2}
					/>
				</div>
			</div>

			{/* Sample Collection */}
			<SampleCollectionScheduleCard
				loading={loading}
				dateTimeSchedules={dateTimeSchedules}
				setDateTimeSchedules={setDateTimeSchedules}
				selectedDateTime={selectedDateTime}
				setSelectedDateTime={setSelectedDateTime}
			/>

			{/* Payment Section */}
			<div className={styles.checkout_paymethod}>
				<div className={styles.checkout_paymethod_head}>
					<p className='ltbody_lg__semibold'>Amount to be paid</p>
					<p className='ltbody_lg__semibold'>
						৳
						{labCheckoutData?.data?.isHardCopyRequired
							? checkoutData.totalAmount
							: labCheckoutData?.data?.totalAmountWithoutConveyance}
					</p>
				</div>
				<div className='flex gap10 p_10'>
					<Radio
						name='cod'
						label={`Cash On Delivery`}
						checked={checked || 'cod'}
						value={'cod'}
						onChange={handlePaymentmetyhodSelect}
						btnSize={`24px`}
						textSize={`14px_600`}
					/>
				</div>
				<div className='flex gap10 p_10'>
					<Radio
						name='online'
						label={`Online Payment`}
						checked={checked}
						value={'online'}
						onChange={handlePaymentmetyhodSelect}
						btnSize={`24px`}
						textSize={`14px_600`}
					/>
				</div>
				<div className={styles.checkout_paymethod_body}>
					{OnlinePaymentMethods?.map((item, i) => (
						<div
							key={i}
							className={styles.checkout_paymethod_img}
							onClick={() => handlePaymentmetyhodSelect('', 'online')}>
							<Image height={45} width={100} src={item.name} alt={item} />
						</div>
					))}

					{userAddress?.location?.district !== 'Dhaka City' && (
						<div className={styles.checkout_payment_note}>
							{v1_checkout_payment_info_image}
							<p className='ltbody_md__regular'>Cash on delivery option is only available for Dhaka</p>
						</div>
					)}
				</div>
			</div>
			<div className={styles.checkout_bottom}>
				{/* <div className="flex gap10 justify-center mb20">
                  <input type="checkbox" /> */}
				<p className='ltbody_md__regular mb20 ltGreyscale900'>
					By continuing you agree to
					<span>Terms & Conditions, Privacy Policy</span> &<span> Refund-Return Policy</span>
				</p>
				{/* </div> */}
				<Button
					// disabled={timeSelected === ''}
					onClick={makeOrder}
					// onClick={makeOrderOnline}
					variant='bgPrimary'
					fullWidth
					disabled={btnDisabled}>
					{checkoutData.paymentMethod === PaymentMethod.ONLINE ? 'Pay Now' : 'Place Order'}
				</Button>
			</div>
		</div>
	);
};

export default Checkout;

const PaymentMethod = {
	COD: 'cod',
	ONLINE: 'online'
};

const ExampleCustomInput = forwardRef(function Btn({ value, onClick }, ref) {
	return (
		<button className='example-custom-input' onClick={onClick} ref={ref}>
			{value}
		</button>
	);
});

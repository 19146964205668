import styles from './Chip.module.css';

interface I_ChipProps {
	onClick?: () => void;
	textSize?: 'sm' | 'lg';
	text: string;
	variant?:
		| 'bgSuccessDark'
		| 'bgSeashell'
		| 'bgPrimary'
		| 'bgCosmicLatte'
		| 'bgAliceBlue'
		| 'bgLavenderBlush'
		| 'bgBrightGray';
	color?: 'clOrange' | 'clAntiFlashWhite' | 'clWhite' | 'clPictonBlue' | 'clErrorBase' | 'clSuccessDark';
	icon?: JSX.Element | string;
}

const Chip = ({ textSize, text, variant, color, icon, onClick }: I_ChipProps) => {
	return (
		<div
			onClick={onClick && onClick}
			className={`
			${styles.chip} 
			${variant ? styles[variant] : ''}
			${color ? styles[color] : 'white'}
			${textSize ? styles[textSize] : 'ltbody_md__regular'}
			flex t_nowrap`}>
			{text}

			{icon && <p className={`ml5`}>{icon}</p>}
		</div>
	);
};

export default Chip;

'use client';

import { useState, useEffect } from 'react';

const useHeaderVisibility = () => {
	const [isHidden, setIsHidden] = useState(false);
	const [prevScrollPos, setPrevScrollPos] = useState(0);

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollPos = window.pageYOffset;

			if (currentScrollPos > prevScrollPos && currentScrollPos > 52) {
				// Hide header
				setIsHidden(true);
			} else {
				// Show header
				setIsHidden(false);
			}

			setPrevScrollPos(currentScrollPos);
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [prevScrollPos]);

	return isHidden;
};

export default useHeaderVisibility;

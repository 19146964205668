import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/brocken_brand.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/broken-image.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/prescription/prescription1.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/prescription/prescription2.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/prescription/prescription3.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/prescription/prescription4.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/prescription/prescription5.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_apple_store.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_avatar.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_cashback.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_default_beauty.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_default_brand.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_default_food.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_default_healthcare.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_default_medicine.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_default_pet_&_vet.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_emptyfav.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_google_play.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_health.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_howToVideo.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_logo.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_order.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_paymentsIcons.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_pharmacy.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_phregister.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_refer.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_upload.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/images/v1_whatsapp.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/atoms/Button/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/atoms/ScrollToTop/ScrollToTop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/HeaderUserInfo/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/MiniCart/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/molecules/SearchInput/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/organisms/StickyCart/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/templates/layout/Footer/footer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/templates/layout/Header/DeliveryAddress.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/templates/layout/Header/Hamburger.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/templates/layout/Header/Navbar.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/templates/layout/Sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CallingModelProvider"] */ "/app/src/services/doc-consultation/context/CallingModalContext.tsx");

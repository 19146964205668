import ContentLoader from 'react-content-loader';

export const TestSkeletonLoader = (props) => (
	<div
		className='d-flex items-flex-end justify-between gap-20 p-10'
		style={{
			width: '100%'
		}}>
		<ContentLoader
			speed={2}
			width={'100%'}
			height={112}
			// viewBox='0 0 950 132'
			backgroundColor='#e8e8e8'
			foregroundColor='#fcfcfc'
			{...props}>
			{/* Image */}
			<rect x='1' y='1' rx='10' ry='10' width='121' height='110' />

			<rect x='137' y='5' rx='2' ry='2' width='240' height='20' />
			<rect x='137' y='35' rx='2' ry='2' width='230' height='15' />
			<rect x='137' y='60' rx='2' ry='2' width='133' height='15' />
			<rect x='137' y='85' rx='2' ry='2' width='240' height='20' />
		</ContentLoader>
		<div className=''>
			<ContentLoader
				speed={2}
				width={'150'}
				height={44}
				// viewBox='0 0 150 44'
				backgroundColor='#e8e8e8'
				foregroundColor='#fcfcfc'
				{...props}>
				{/* Button */}
				<rect x='1' y='1' rx='10' ry='10' width='150' height='44' />
			</ContentLoader>
		</div>
	</div>
);

export const AdToCartTestSkeletonLoader = (props) => (
	<div
		className='d-flex items-flex-end justify-between gap-20 p-10'
		style={{
			width: '100%'
		}}>
		<ContentLoader
			speed={2}
			width={'100%'}
			height={85}
			// viewBox='0 0 300 85'
			backgroundColor='#e8e8e8'
			foregroundColor='#fcfcfc'
			{...props}>
			{/* Image */}
			<rect x='1' y='1' rx='10' ry='10' width='85' height='85' />

			<rect x='95' y='10' rx='2' ry='2' width='140' height='10' />
			<rect x='95' y='30' rx='2' ry='2' width='130' height='10' />
			<rect x='95' y='50' rx='2' ry='2' width='80' height='10' />
			<rect x='95' y='70' rx='2' ry='2' width='120' height='10' />
		</ContentLoader>
		<div className=''>
			<ContentLoader
				speed={2}
				width={'100'}
				height={28}
				// viewBox='0 0 100 28'
				backgroundColor='#e8e8e8'
				foregroundColor='#fcfcfc'
				{...props}>
				{/* Button */}
				<rect x='1' y='1' rx='10' ry='10' width='100' height='28' />
			</ContentLoader>
		</div>
	</div>
);

import { useState, useEffect } from 'react';

function useAtTop() {
	const [isAtTop, setIsAtTop] = useState(true);

	useEffect(() => {
		function handleScroll() {
			setIsAtTop(window.scrollY === 0);
		}

		// Attach the event listener
		window.addEventListener('scroll', handleScroll);

		// Clean up the event listener on component unmount
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []); // Empty dependency array means this useEffect runs once on mount and unmount

	return isAtTop;
}

export default useAtTop;

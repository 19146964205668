'use client';

import React, { useState, useEffect } from 'react';
import styles from './ScrollToTop.module.scss'; // Assuming you have a SCSS module for styling
import { IoIosArrowUp } from 'react-icons/io';

const ScrollToTop = () => {
	const [isVisible, setIsVisible] = useState(false);

	// Function to detect scroll position
	const toggleVisibility = () => {
		if (window.pageYOffset > 300) {
			setIsVisible(true);
		} else {
			setIsVisible(false);
		}
	};

	// Function to scroll to top
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	useEffect(() => {
		window.addEventListener('scroll', toggleVisibility);

		return () => {
			window.removeEventListener('scroll', toggleVisibility);
		};
	}, []);

	return (
		isVisible && (
			<button onClick={scrollToTop} className={styles.scrollToTop}>
				<IoIosArrowUp />
			</button>
		)
	);
};

export default ScrollToTop;

'use client';

import cls from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import styles from './select.module.scss';

interface Option {
	value: string;
	label: string;
}

interface SelectProps {
	options: Option[];
	onChange: (value: string) => void;
	size?: 'large' | 'middle' | 'small';
	dropdownStyle?: React.CSSProperties;
	style?: React.CSSProperties;
	value?: string;
	defaultValue?: string;
	className?: string; // Add className prop
	placeholder?: string;
	searchable?: boolean;
	loadData?: any;
	surfix?: React.ReactNode;
	prefix?: React.ReactNode;
	ellipsis?: boolean;
	disabled?: boolean;
	valueStyle?: React.CSSProperties;
}

const SelectCart: React.FC<SelectProps> = ({
	options,
	onChange,
	size = 'middle',
	dropdownStyle,
	style,
	value,
	defaultValue,
	className, // Include className prop
	placeholder,
	searchable,
	loadData,
	surfix,
	prefix,
	ellipsis = false,
	disabled = false,
	valueStyle
}) => {
	const [dropdownPosition, setDropdownPosition] = useState<'top' | 'bottom'>('bottom');
	const [optionsData, setOptionsData] = useState<Option[]>(options); // Add this line

	const [isOpen, setIsOpen] = useState(false);
	const [selectedValue, setSelectedValue] = useState<string | null>(defaultValue || null);
	const [searchTerm, setSearchTerm] = useState<string>('');

	const [animationState, setAnimationState] = useState<'entering' | 'entered' | 'exiting' | 'exited'>('exited');
	const selectRef = useRef<HTMLDivElement>(null);

	const handleOptionClick = (value: string) => {
		setSelectedValue(value);
		setIsOpen(false);
		onChange(value);
		setSearchTerm('');
	};

	const getSizeClass = () => {
		switch (size) {
			case 'large':
				return styles.select_large;
			case 'small':
				return styles.select_small;
			case 'middle':
			default:
				return styles.select_middle;
		}
	};
	const searchInputRef = useRef<HTMLInputElement>(null);

	const getLabelForValue = (val: string | null): string => {
		const matchedOption = optionsData.find((option) => option.value === val);
		// show only the Number of the selected option

		// surfix;
		const surfixValue = surfix ? surfix : '';
		const prefixValue = prefix ? prefix : '';
		if (matchedOption) {
			return `${prefixValue} ${matchedOption.value} ${surfixValue}`;
		}
		return '';
	};

	// Update this function to handle animations
	const handleDropdownToggle = (event) => {
		if (disabled) return;

		event.stopPropagation();
		if (isOpen) {
			setAnimationState('exiting');
			setTimeout(() => {
				setIsOpen(false);
				setAnimationState('exited');
			}, 200); // This duration should match the transition duration in the SCSS
		} else {
			setIsOpen(true);
			setAnimationState('entering');
			setTimeout(() => {
				setAnimationState('entered');
			}, 200); // This duration should match the transition duration in the SCSS
		}
		if (selectRef.current) {
			const rect = selectRef.current.getBoundingClientRect();
			const spaceAtBottom = window.innerHeight - rect.bottom;
			const dropdownHeight = 250;

			if (spaceAtBottom < dropdownHeight) {
				setDropdownPosition('top');
			} else {
				setDropdownPosition('bottom');
			}
		}

		setSearchTerm('');
	};

	const handleOutsideClick = (event) => {
		if (selectRef.current && !selectRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleOutsideClick);
		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, []);
	useEffect(() => {
		if (isOpen && searchable && searchInputRef.current) {
			searchInputRef.current.focus();
		}
	}, [isOpen, searchable]);

	return (
		<div onClick={handleDropdownToggle} className='relative' ref={selectRef}>
			<div
				className={`${styles.select_container} ${getSizeClass()} ${className}`} // Include
				style={{
					...style,
					cursor: 'pointer'
				}}>
				<div onClick={handleDropdownToggle}>
					{isOpen && searchable ? (
						<input
							ref={searchInputRef}
							type='text'
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
							placeholder={placeholder}
							className={styles.select_search} // you'd need to style this
						/>
					) : (
						<span
							style={valueStyle}
							// className={`${styles.select_value} ${
							// 	ellipsis ? styles.current_select : ""
							// } ${disabled ? styles.disabled : ""}
							// 	`}
							className={cls(
								styles.select_value,
								ellipsis && styles.current_select,
								disabled && styles.disabled
							)}
							onClick={handleDropdownToggle}>
							{getLabelForValue(value || selectedValue) || placeholder}
						</span>
					)}
				</div>
				{(isOpen || animationState === 'exiting') && (
					<div
						className={`${styles.select_options} ${styles['select_options-' + animationState]}`}
						style={{
							...dropdownStyle,
							top: dropdownPosition === 'bottom' ? '-110%' : 'unset',
							bottom: dropdownPosition === 'top' ? '110%' : 'unset'
						}}>
						{optionsData
							.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()))
							.map((option) => (
								<div
									key={option.value}
									className={cls(
										styles.select_option,

										{
											[styles.selected]: selectedValue === option.value
										}
									)}
									onClick={() => handleOptionClick(option.value)}>
									{option.label}
								</div>
							))}
					</div>
				)}
			</div>
		</div>
	);
};

export default SelectCart;

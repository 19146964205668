import querystring from 'qs';
import { request } from '../arogga-api';

export const getLabTestsOrders = (q = '', user: any) => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `lab-order/api/v1/orders?${qs}`,
		method: 'GET',
		user
	});
};

export const getLabTestsOrderDetails = (id: string) => {
	return request({
		endpoint: `lab-order/api/v1/orders/${id}`,
		method: 'GET'
	});
};

export const cancelLabTestOrder = (id: string, cancelReason: string) => {
	return request({
		endpoint: `lab-order/api/v1/orders/${id}/cancellation`,
		method: 'PUT',
		body: {
			cancelReason
		}
	});
};

export const getLabTestOrderCancelReasons = (id: string) => {
	return request({
		endpoint: `lab-order/api/v1/shared/order-cancel-reasons`,
		method: 'GET'
	});
};

export const placeOrder = (body: any) => {
	return request({
		endpoint: `lab-order/api/v1/orders`,
		// endpoint: `api/v1/orders`,
		method: 'POST',
		body
	});
};

export const getSampleCollectionSchedule = () => {
	return request({
		endpoint: `lab-order/api/v1/shared/schedule-dates`,
		method: 'GET'
	});
};

export const updateSampleCollectionSchedule = (orderId: string, body: any) => {
	return request({
		endpoint: `lab-order/api/v1/orders/${orderId}/rescheduling`,
		method: 'PUT',
		body
	});
};

export const onlinePaymentForOrder = (orderId: string, body: any) => {
	return request({
		endpoint: `lab-order/api/v1/orders/${orderId}/payment`,
		method: 'GET'
	});
};

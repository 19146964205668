import { RotatingLines } from 'react-loader-spinner';

const SmallSpinner = () => {
	return (
		<RotatingLines
			visible={true}
			height='22'
			width='24'
			color='grey'
			strokeWidth='5'
			animationDuration='0.75'
			ariaLabel='rotating-lines-loading'
			wrapperStyle={{}}
			wrapperClass=''
		/>
	);
};

export default SmallSpinner;

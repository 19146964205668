// components/CouponCodeInput.js

import { useEffect, useState } from 'react';
import { BiSolidOffer } from 'react-icons/bi';
import styles from './CouponCodeInput.module.scss';

interface Props {
	onApply?: (code: string) => Promise<any>;
	value?: any;
	style?: any;
	applyButtonText?: string;
	pleaceholder?: string;
	inputRef?: any;
	id?: string;
	onReset?: (e: any) => void;
}

const CouponCodeInput = ({
	onApply,
	value,
	style,
	applyButtonText = 'Apply',
	pleaceholder = 'Enter Coupon Code',
	inputRef,
	id,
	onReset
}: Props) => {
	const [code, setCode] = useState(value) as any;

	const handleApplyClick = () => {
		if (value.length !== 0) {
			setCode('');
			onReset && onReset('');
			return;
		}
		if (onApply) {
			onApply(code).then((res) => {
				if (!res) {
					setCode('');
				}
			});
		}
	};

	useEffect(() => {
		setCode(value);
	}, [value]);

	return (
		<div className={styles.container} style={style}>
			<div className={styles.iconContainer}>
				<BiSolidOffer size={20} className={styles.icon} />{' '}
			</div>
			<input
				id={id}
				ref={inputRef || null}
				className={styles.input}
				type='text'
				placeholder={pleaceholder}
				value={code}
				disabled={value?.length > 0}
				onChange={(e) => setCode(e.target.value)}
			/>
			<button
				className={styles.button}
				style={{
					backgroundColor: value?.length > 0 && 'red'
				}}
				onClick={handleApplyClick}>
				{applyButtonText}
			</button>
		</div>
	);
};

export default CouponCodeInput;

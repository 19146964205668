'use client';

import React from 'react';
import styles from './CheckBox.module.scss';
import cls from 'classnames';

interface CheckBoxProps {
	id?: string;
	label: React.ReactNode;
	onChange?: (value: boolean) => void;
	count?: React.ReactNode;
	checked?: boolean;
	value?: any;
	defaultChecked?: boolean;
	style?: React.CSSProperties;
	inputStyle?: React.CSSProperties;
	labelStyle?: React.CSSProperties;
	labelClassName?: string;
}
const CheckBox: React.FC<CheckBoxProps> = ({ label, ...props }) => {
	return (
		<div className={cls(styles.checkboxWrapper)}>
			<input
				type='checkbox'
				{...props}
				style={props.inputStyle}
				onChange={(e: any) => {
					if (props.onChange) {
						if (props.value) {
							props.onChange(e);
						} else {
							props.onChange(e.target.checked);
						}
					}
				}}
			/>
			<div className={cls(styles.checkboxLabel, props.labelClassName)} style={props.labelStyle}>
				<label>{label}</label>
				{props.count && <span className='text-grey500'>{props.count}</span>}
			</div>
		</div>
	);
};

export default CheckBox;

import Button from '@/labtest/presentation/atoms/Button';
import BookforItem from '@labtest/presentation/atoms/BookforItem';
import IconWrapper from '@labtest/presentation/atoms/IconWrapper';
import Popover from '@labtest/presentation/atoms/Popover';

import { isEmptyObject } from '@labServices/utils/cmnHelper';
import { useState } from 'react';
import { FaBriefcase, FaHouse } from 'react-icons/fa6';
import { MdEdit, MdOutlineApartment } from 'react-icons/md';

import styles from './Address.module.css';
import Chip from '@labtest/presentation/atoms/Chip';

const Address = ({
	variant,
	onClick,
	handleMakeDefault,
	handleEdit,
	handleDelete,
	data,
	defaultAddress,
	editable = false,
	changeable = false,
	setShowAddressModal,
	children
}) => {
	const [open, setOpen] = useState(false);
	const showAdressOpt = (e) => {
		e.stopPropagation();
		setOpen(true);
	};
	const closeAdressOpt = () => {
		setOpen(false);
	};

	// console.log('data:', data);

	return (
		<div
			onClick={(e) => {
				e.stopPropagation();
				onClick?.(data);
			}}
			className={styles.address}>
			<div className={`${styles.address_head} ${variant ? styles[variant] : ''}`}>
				<div className='flex fullWidth justify-between mr5'>
					<p className='ltbody_md__semibold ltGreyscale900'>
						{data?.ul_type ? `${data?.ul_type} Address` : `Shipping Address`}
					</p>
					{data?.ul_default ? <Chip text={'Default'} variant='primary' /> : ''}
				</div>

				{editable && (
					<div className='relative'>
						{
							<span onClick={showAdressOpt} className='material-symbols-outlined'>
								more_vert
							</span>
						}
						<Popover open={open} handleClose={closeAdressOpt}>
							<BookforItem
								onClick={(e) => {
									e.stopPropagation();
									handleEdit(data);
									closeAdressOpt();
								}}>
								Edit
							</BookforItem>
							<BookforItem
								onClick={(e) => {
									e.stopPropagation();
									handleMakeDefault(data);
									closeAdressOpt();
								}}>
								Make Default
							</BookforItem>
							<BookforItem
								onClick={(e) => {
									e.stopPropagation();
									handleDelete(data);
									closeAdressOpt();
								}}>
								Delete
							</BookforItem>
						</Popover>
					</div>
				)}

				{changeable && (
					<span onClick={setShowAddressModal}>
						<p className='ltbody_md__semibold '>Change</p>
						<MdEdit />
					</span>
				)}
			</div>
			<>
				{!isEmptyObject(data?.ul_location) ? (
					<div className={styles.address_text}>
						{/* <span className="material-symbols-outlined">home</span> */}
						<div className={styles.icon_container}>
							<IconWrapper>{getIcon(data?.ul_type)}</IconWrapper>
						</div>
						<div className='flex_1'>
							<p className='ltbody_md__medium p_5 ltGreyscale900'>
								Name : <span className='ltbody_md__regular ltGreyscale600'>{data?.ul_name || 'N/A'}</span>
							</p>
							<p className='ltbody_md__medium p_5 ltGreyscale900'>
								Phone : <span className='ltbody_md__regular ltGreyscale600'>{data?.ul_mobile || 'N/A'}</span>
							</p>
							<p className='ltbody_md__medium p_5 ltGreyscale900'>
								Address :{' '}
								<span className='ltbody_md__regular ltGreyscale600'>
									{data?.ul_location ? data?.ul_location : defaultAddress || 'N/A'}
								</span>
							</p>
						</div>
					</div>
				) : (
					<div className='p_10'>
						<p className='mb10 ltGreyscale500'>You {"haven't"} added any address yet.</p>
						<Button
							variant='bgPrimary'
							fullWidth
							onClick={() => {
								setShowAddressModal(true);
							}}>
							Add New Address
						</Button>
					</div>
				)}
			</>
		</div>
	);
};

export default Address;

export const getIcon = (type) => {
	switch (type) {
		case 'Home':
			return <FaHouse size={24} />;
		case 'Office':
			return <FaBriefcase size={24} />;
		case 'Home Town':
			return <MdOutlineApartment size={24} />;
		default:
			return <FaHouse size={24} />;
	}
};

import styles from './Checkbox.module.css';

const Checkbox = ({ checked, onChange, size, ...rest }) => {
	return (
		<div className={styles.checkboxOverride}>
			<input
				{...rest}
				className={styles.checkbox}
				checked={checked}
				onChange={onChange}
				type='checkbox'
				id='checkboxInputOverride'
			/>
			<label className={`${styles.label} ${styles[size]}`} htmlFor='checkboxInputOverride'></label>
		</div>
	);
};

export default Checkbox;

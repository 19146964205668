'use client';

import { useState } from 'react';
import useSWR from 'swr';
import AroggaAPI from './AroggaAPI';
type FetchMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

interface FetchOptions {
	method?: FetchMethod;
	body?: any;
	headers?: Record<string, string>;
	cache?: 'default' | 'no-store' | 'reload' | 'no-cache' | 'force-cache' | 'only-if-cached';
}

interface FetchResponse<T> {
	status: string;
	data: T | null | undefined | any;
	error?: string;
	message?: string;
	route?: string;
	isLoading?: boolean;
	isError?: boolean;
	mutate?: any;
	refetchWithNewData?: any;
	config?: any;
}
const AroggaSWR = (endpoint: string, method: FetchMethod = 'GET', body?: any, config?: object): FetchResponse<any> => {
	const [key, setKey] = useState([endpoint, method, body]);
	const [isLoading, setIsLoading] = useState(true); // Initialize with true to indicate loading on mount

	const { data, error, mutate } = useSWR(
		[endpoint, method, body],
		async (...args) => {
			setIsLoading(true);
			const [endpoint, method, body, headers] = args.flat();
			const result = await AroggaAPI(endpoint, {
				method,
				body,
				headers
			});
			setIsLoading(false); // Set loading to false once we have a result
			return result;
		},
		{
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
			shouldRetryOnError: true,
			revalidateOnMount: true,
			...config
		}
	);

	const refetchWithNewData = (newEndpoint: string, newMethod: FetchMethod, newBody: any) => {
		const refetchWithNewData = (newEndpoint: string | null, newMethod: FetchMethod | null, newBody: any) => {
			setKey([
				newEndpoint || key[0], // Use new endpoint or fall back to the previous endpoint
				newMethod || key[1], // Use new method or fall back to the previous method
				newBody
			]);
		};
	};

	if (error) {
		return {
			status: 'fail',
			isLoading,
			isError: error,
			data: null,
			message: error.message,
			route: endpoint
		};
	}

	return {
		...data,
		isLoading,
		isError: error,
		mutate,
		refetchWithNewData
	};
};

export default AroggaSWR;

import { IC_CHECK, IC_COLD, IC_DELETE, IC_EMPTY_CART, IC_EXPRESS, IC_REGULAR, IC_RX, IC_TAKA } from '@/assets/icons';
import Button from '@/components/atoms/Button';
import Card from '@/components/atoms/Card';
import CouponCodeInput from '@/components/atoms/CouponCodeInput';
import DeliveryType from '@/components/atoms/DeliveryType';
import Descriptions from '@/components/atoms/Descriptions';
import Select from '@/components/atoms/Select';
import SelectCart from '@/components/atoms/SelectCart';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import UploadPrescriptionButton from '@/components/atoms/UploadPrescriptionButton';
import PrescriptionImage from '@/components/molecules/PrescriptionImage';
import { useAlert } from '@/contexts/AlertProvider';
import useCart from '@/contexts/CartProvider';
import AroggaAPI from '@/services/apis/AroggaAPI';
import AroggaSWR from '@/services/apis/AroggaSWR';
import { API_CART } from '@/services/apis/apiConstrant';
import {
	formatPrice,
	generateProductLink,
	generateQuantityOptions,
	getProductImage,
	renderLabel,
	transformSlots
} from '@/services/utils/helper';
import cls from 'classnames';
import { get, isEmpty, isObject, map, some } from 'lodash';
import Image from 'next/image';
import Link from 'next/link';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { FaBriefcase, FaPen } from 'react-icons/fa6';
import { MdError, MdHomeFilled, MdInfo, MdOutlineApartment, MdRadioButtonUnchecked } from 'react-icons/md';
import styles from './sticky-cart.module.scss';

const MedicineCart = ({
	styles,
	userType,
	setPrescriptionLoading,
	setScheduleId,
	deliveryOption,
	setDeliveryOption
}) => {
	const { carts, cartAction, removeCart, getCart, drawerOpen, setShowAddressModal, setCarts } = useCart();
	const [showCouponCode, setShowCouponCode] = useState(false);
	const [appliedCouponCode, setAppliedCouponCode] = useState('');
	const resetCouponCodeRef = useRef(null);

	const getPrices = (item: any) => {
		const price = item.pv[`pv_${userType}_price`] * item.qty;
		const mrp = item.pv[`pv_${userType}_mrp`] * item.qty;

		return {
			price: formatPrice(price),
			mrp: formatPrice(mrp)
		};
	};
	const alert = useAlert();
	const removePrescription = async (key: any) => {
		alert.warning({
			title: 'Are you sure?',
			message: 'You want to remove this prescription?',
			onConfirm: async () => {
				const { data, status } = await AroggaAPI.delete(API_CART.REMOVE_PRESCRIPTION_FROM_CART(key));
				if (status === 'success') {
					alert.success({
						title: 'Success',
						message: 'Prescription removed successfully',
						provider: 'toast'
					});
				}
				if (data) {
					getCart();
				}
			},
			onCancel: () => {},
			cancelText: 'No',
			confirmText: 'Yes'
		});
	};
	const handleCouponCode = async (code: any) => {
		const { status, message, data } = await AroggaAPI.post(API_CART.CHECK_COUPON_CODE, {
			coupon_code: code
		});
		if (status === 'fail') {
			alert.error({
				title: 'Oops !',
				message: message
			});
			return false;
		} else {
			alert.success({
				title: 'Success',
				message: 'Coupon code applied successfully'
			});
			setCarts(data);

			return true;
		}
	};
	const hasProducts = get(carts, 'products.length', 0) > 0;
	const hasPrescriptions = get(carts, 'prescriptions.length', 0) > 0;
	const hasProductsORPrescriptions = hasProducts || hasPrescriptions;
	const deductionsAmount = carts?.deductions_amount ?? 0;
	const poCashBack = carts?.po_cash_back ?? 0;
	const showDeductions = deductionsAmount > 0;
	const showPoCashBack = poCashBack > 0;
	const isDhakaCity = get(carts, 'uc_address.l_district') === 'Dhaka City';
	const isCodeApplied = !!get(carts, 'd_code');
	useEffect(() => {
		if (isCodeApplied) {
			setAppliedCouponCode(carts?.d_code);
		} else {
			setAppliedCouponCode('');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [carts]);

	//find p_rx_req 1 from carts.products array and return true
	const isPrescriptionRequired = some(carts?.products, {
		p_rx_req: 1
	});
	const showPrescription = isPrescriptionRequired || hasPrescriptions;
	const handelResetCouponCode = () => {
		AroggaAPI.post(API_CART.RESET_COUPON_CODE).then((res) => {
			if (res.status === 'success') {
				alert.success({
					title: 'Success',
					message: 'Coupon code removed successfully'
				});
			}
			getCart();
			setAppliedCouponCode('');
		});
	};

	return hasProductsORPrescriptions ? (
		<div>
			{hasProducts && (
				<div className={styles.drawer__body__items}>
					{carts?.products?.map((item: any, i: any) => {
						const deleveryType = !!item?.stock_status ? false : true;
						const label = renderLabel({
							qty: item.qty,
							salesUnit: item?.pv?.pu_unit_label,
							baseUnit: item?.pv?.pu_base_unit_label,
							unitMultiplier: item?.pv?.pu_multiplier
						});
						const getDiscountPercentage = (price, mrp) => {
							return Math.round(((mrp - price) / mrp) * 100);
						};

						const options = generateQuantityOptions(
							item.pv[`pv_${userType}_min_qty`],
							item.pv[`pv_${userType}_max_qty`],
							item.pv[`pu_unit_label`],
							item?.pv?.pu_multiplier,
							item.pv[`pu_base_unit_label`]
						) || [
							{
								label: '1',
								value: 1
							}
						];
						const currentPv = get(item, 'pv', {});

						return (
							<Fragment key={i}>
								<div className={styles.items__item}>
									<div className='d-flex justify-content-between w-full'>
										<div
											className={styles.items__left}
											style={{
												width: '75%'
											}}>
											<div className={styles.item__image}>
												<Link href={generateProductLink(item, item.pv)}>
													<Image
														width={84}
														height={84}
														unoptimized={true}
														src={getProductImage(item)}
														alt={item.p_name}
														style={{
															objectFit: 'cover'
														}}
													/>
													{getPrices(item).price !== getPrices(item).mrp && (
														<span className={styles.item__discount}>
															<span>
																{getDiscountPercentage(getPrices(item).price, getPrices(item).mrp)}% OFF
															</span>
														</span>
													)}
												</Link>
											</div>
											<div className={styles.item__details}>
												<div className={styles.item_name}>
													<Link href={generateProductLink(item, item.pv)} className={styles.item_name}>
														<Tooltip content={item.p_name} size='small'>
															{item.p_name}
														</Tooltip>
													</Link>
												</div>
												{item.p_strength && (
													<div
														className='text-10 text-grey500 fw-400'
														style={{
															whiteSpace: 'nowrap',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
															width: '90%'
														}}>
														<Tooltip
															content={`${item.p_strength} ${item.p_form && ` - ${item.p_form}`}`}
															size='small'>
															{item.p_strength} {item.p_form && ` - ${item.p_form}`}
														</Tooltip>
													</div>
												)}

												{item.p_brand && (
													<div
														className='text-10 text-grey900 fw-400'
														style={{
															whiteSpace: 'nowrap',
															overflow: 'hidden',
															textOverflow: 'ellipsis',
															width: '90%'
														}}>
														<Tooltip content={item.p_brand} size='small'>
															{item.p_brand}
														</Tooltip>
													</div>
												)}

												<div
													className='text-10 text-grey500 fw-300'
													style={{
														whiteSpace: 'nowrap',
														overflow: 'hidden',
														textOverflow: 'ellipsis',
														width: '80%'
													}}>
													<Tooltip content={label} size='small'>
														{label}
													</Tooltip>
												</div>
												{Object.keys(currentPv?.pv_attribute || {}).length > 0 &&
													map(currentPv.pv_attribute, (value, key) => (
														<div
															key={key}
															className='text-10 text-grey900 fw-400 text-capitalize'
															style={{
																whiteSpace: 'nowrap',
																overflow: 'hidden',
																textOverflow: 'ellipsis',
																width: '90%'
															}}>
															<Tooltip content={`${key.toLocaleUpperCase()}: ${value}`} size='small'>
																{key} : &nbsp;
																{value}
															</Tooltip>
														</div>
													))}
											</div>
										</div>

										<div
											className={styles.tems__right}
											style={{
												width: '25%',
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'space-between',
												alignItems: 'flex-end'
											}}>
											<div
												style={{
													display: 'flex',
													cursor: 'pointer',
													justifyContent: 'center',
													gap: '5px',
													alignItems: 'center'
												}}>
												{!!item?.p_cold && (
													<Tooltip content='Cold Item' size='small'>
														<IC_COLD
															style={{
																height: '22px',
																width: '22px'
															}}
														/>
													</Tooltip>
												)}
												{!!item?.p_rx_req && (
													<Tooltip content='Prescription Required' size='small'>
														<IC_RX
															style={{
																height: '20px',
																width: '20px'
															}}
														/>
													</Tooltip>
												)}

												{isDhakaCity && (
													<>
														{deleveryType ? (
															<Tooltip content='Regular Delivery' size='small'>
																<IC_REGULAR
																	style={{
																		height: '18px',
																		width: '18px'
																	}}
																/>
															</Tooltip>
														) : (
															<Tooltip content='Express Delivery' size='small'>
																<IC_EXPRESS
																	style={{
																		height: '18px',
																		width: '18px'
																	}}
																/>
															</Tooltip>
														)}
													</>
												)}

												<Tooltip content='Remove' size='small'>
													<IC_DELETE onClick={() => removeCart(item?.pv?.id, item?.p_name)} />
												</Tooltip>
											</div>
											<div className='d-flex justify-content-center align-items-center'>
												<del className='text-12 text-grey500 mr-10'>৳{getPrices(item).mrp}</del>
												<div className='text-12 text-grey900 fw-600'>৳{getPrices(item).price}</div>
											</div>
											<div className='d-flex justify-content-center align-items-center'>
												{!isDhakaCity && !!item?.p_cold && !isEmpty(carts?.uc_address) ? (
													<div></div>
												) : (
													<SelectCart
														valueStyle={{
															color: '#0E7673',
															fontWeight: '500'
														}}
														style={{
															width: '80px',
															height: '30px',
															borderRadius: '5px',
															display: 'flex',
															alignItems: 'center',
															fontSize: '12px',
															fontWeight: '500',
															border: '1px solid #0E7673',
															color: '#0E7673'
														}}
														ellipsis={false}
														size='small'
														prefix={'Qty:'}
														value={item.qty || 1}
														defaultValue={item.qty}
														options={options as any}
														onChange={(e) => {
															const newCart = {
																id: item.id,
																quantity: e,
																variant: item.pv.id,
																baseUnit: item.pu.id
															};
															cartAction(newCart);
														}}
													/>
												)}
											</div>
										</div>
									</div>
									{!isDhakaCity && !!item?.p_cold && !isEmpty(carts?.uc_address) && (
										<div className='text-10 text-error fw-400 mt-5'>
											দুঃখিত এই মেডিসিনটি তাপ সংবেদনশীল, তাই ঢাকা শহরের বাইরে আপাতত ডেলিভারি হচ্ছে না।
										</div>
									)}
								</div>
							</Fragment>
						);
					})}
				</div>
			)}

			{showPrescription && (
				<Card
					style={{
						boxShadow: '0px 2px 15px 0px rgba(0, 0, 0, 0.1)',
						marginBottom: '16px'
					}}
					bodyStyle={{
						margin: 0
					}}
					size='small'
					className='mb-16'
					headerStyle={{
						paddingInline: '10px'
					}}
					title='Upload Prescription'>
					<div
						style={{
							// 5 grid
							display: 'grid',
							gridTemplateColumns: 'repeat(5, 1fr)',
							gap: '10px'
						}}>
						{map(carts.prescriptions, (item, index) => (
							<PrescriptionImage
								key={index}
								preview={true}
								size='small'
								src={item.src}
								label={<div className='d-flex justify-content-center items-center'>Remove</div>}
								onClick={() => {
									removePrescription(item.title);
								}}
							/>
						))}

						{carts.prescriptions.length < 5 && (
							<UploadPrescriptionButton
								style={{
									width: '56.6px',
									height: 70,
									backgroundColor: '#E7F1F1',
									display: 'flex',
									justifyContent: 'center',
									marginBottom: 0
								}}
								icon={
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='30'
										height='30'
										viewBox='0 0 30 30'
										fill='none'>
										<path
											fillRule='evenodd'
											clipRule='evenodd'
											d='M7.995 0H21.99C27.09 0 30 2.88 30 7.995V22.005C30 27.09 27.105 30 22.005 30H7.995C2.88 30 0 27.09 0 22.005V7.995C0 2.88 2.88 0 7.995 0ZM16.23 16.245H20.49C21.18 16.23 21.735 15.675 21.735 14.985C21.735 14.295 21.18 13.74 20.49 13.74H16.23V9.51C16.23 8.82 15.675 8.265 14.985 8.265C14.295 8.265 13.74 8.82 13.74 9.51V13.74H9.495C9.165 13.74 8.85 13.875 8.61 14.1C8.385 14.34 8.25 14.6535 8.25 14.985C8.25 15.675 8.805 16.23 9.495 16.245H13.74V20.49C13.74 21.18 14.295 21.735 14.985 21.735C15.675 21.735 16.23 21.18 16.23 20.49V16.245Z'
											fill='#0E7673'
										/>
									</svg>
								}
								iconStyle={{
									height: '24px',
									width: '24px',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center'
								}}
								showLabel={false}
								addToCart={1}
								onSuccess={(message: any) => {
									getCart();
								}}
								setLoading={setPrescriptionLoading}
							/>
						)}
					</div>
				</Card>
			)}
			<div
				className={styles.address}
				style={{
					boxShadow: '0px 2px 15px 0px rgba(0, 0, 0, 0.1)',
					borderRadius: '10px'
				}}>
				<Card
					size='small'
					className='mb-16'
					bodyStyle={{
						margin: 0
					}}
					title='Address Shipping'
					extra={
						!isEmpty(carts?.uc_address) && (
							<span
								className='text-primary fw-500 d-flex gap-4 items-center'
								onClick={() => setShowAddressModal(true)}>
								Change
								<FaPen />
							</span>
						)
					}>
					<div className='d-flex justify-content-center gap-10'>
						{carts?.uc_address?.ul_type === 'Home' && (
							<MdHomeFilled
								size={24}
								style={{
									color: '#718096',
									minWidth: '24px'
								}}
							/>
						)}
						{carts?.uc_address?.ul_type === 'Office' && (
							<FaBriefcase size={24} style={{ color: '#718096', minWidth: '24px' }} />
						)}
						{carts?.uc_address?.ul_type === 'Hometown' && (
							<MdOutlineApartment size={24} style={{ color: '#718096', minWidth: '24px' }} />
						)}

						{isEmpty(carts?.uc_address) ? (
							<div className='d-flex flex-column gap-10 w-full'>
								<div className='text-grey900 fw-400'>You haven&apos;t added any address yet.</div>
								<div className=''>
									<Button onClick={() => setShowAddressModal(true)}>Add New Address</Button>
								</div>
							</div>
						) : (
							<div className='d-flex flex-column gap-3'>
								<span>{carts?.uc_address?.ul_name}</span>
								<span>{carts?.uc_address?.ul_mobile}</span>
								<span>{carts?.uc_address?.ul_location}</span>
							</div>
						)}
					</div>
				</Card>
			</div>

			{!hasProducts && (
				<div>
					<Card
						style={{
							border: '1px dashed #E2E8F0',
							boxShadow: '0px 2px 15px 0px rgba(0, 0, 0, 0.1)',
							borderRadius: '10px'
						}}>
						One arogga representative will call you shortly for confirming this order. You may receive cashback
						based on the final order value.
					</Card>{' '}
				</div>
			)}

			{hasProducts && (
				<div
					className={styles.payment}
					id='sticky-cart-payment-summary'
					style={{
						boxShadow: '0px 2px 15px 0px rgba(0, 0, 0, 0.1)',
						borderRadius: '10px'
					}}>
					<div className='p-10'>
						<div
							className='text-14 text-grey600 fw-500 cursor-pointer'
							onClick={() => !isCodeApplied && setShowCouponCode(!showCouponCode)}
							style={{
								textDecoration: 'underline',
								lineHeight: '160%'
							}}>
							Have coupon code ?
						</div>
						<CouponCodeInput
							inputRef={resetCouponCodeRef}
							id='couponCode'
							style={{
								boxShadow: '0px 2px 15px 0px rgba(0, 0, 0, 0.1)',
								borderRadius: '10px',
								marginTop: '10px',
								display: showCouponCode ? '' : isCodeApplied ? '' : 'none',
								marginBottom: '0px'
							}}
							onApply={handleCouponCode}
							onReset={(e) => handelResetCouponCode()}
							applyButtonText={appliedCouponCode ? 'Remove' : 'Apply'}
							value={appliedCouponCode}
						/>
					</div>

					{(showDeductions || showPoCashBack) && (
						<div
							className={styles.shopping_cart_offer_card}
							style={{
								borderRadius: '10px',
								marginRight: '10px',
								marginLeft: '10px'
							}}>
							{showDeductions && (
								<div>
									<span>
										<IC_TAKA />
									</span>
									<span>You are saving ৳{deductionsAmount} in this order.</span>
								</div>
							)}
							{showPoCashBack && (
								<div>
									<span>
										<IC_TAKA />
									</span>
									<span>You will receive ৳{poCashBack} cashback after delivery.</span>
								</div>
							)}
						</div>
					)}
					<div className={styles.payment__item}>
						<Descriptions
							className=''
							titleClassName='text-12 fw-500'
							title='Subtotal (MRP)'
							value={`৳${carts?.c_total_mrp}`}
							valueClassName='text-12 fw-500'
						/>

						{map(carts?.deductions, (item, key) => {
							return (
								<Descriptions
									key={key}
									className='pt-5'
									titleClassName='text-12 fw-500 text-grey900'
									title={item?.text}
									value={`-৳${item?.amount}`}
									valueClassName='text-error text-12 fw-500 '
								/>
							);
						})}
						{carts?.po_user_bonus_used > 0 && (
							<Descriptions
								className='mt-10'
								titleClassName='text-14 fw-500'
								title='Arogga Bonus applied'
								value={`-৳${carts?.po_user_bonus_used}`}
								valueClassName='text-error text-14 fw-500'
							/>
						)}
						{carts?.po_user_cash_used > 0 && (
							<Descriptions
								className='mt-10'
								titleClassName='text-14 fw-500'
								title='Arogga cash applied'
								value={`-৳${carts?.po_user_cash_used}`}
								valueClassName='text-error text-14 fw-500'
							/>
						)}
					</div>
					{drawerOpen && (
						<div className='pr-10'>
							<SelectDeliveryType
								de_options={carts?.de_options}
								setScheduleId={(id: any) => setScheduleId(id)}
								option={deliveryOption}
								setOption={(option: any) => setDeliveryOption(option)}
							/>
						</div>
					)}
					<div className={styles.payment__item_sep}></div>

					<Descriptions
						className='px-17 py-10'
						titleClassName='text-12 fw-500 text-grey900'
						title='Amount Payable'
						value={`৳${carts?.po_payable_total}`}
						valueClassName='text-primary text-14 fw-500'
					/>
				</div>
			)}
		</div>
	) : (
		<div>
			<div className={styles.empty}>
				<div className='d-flex justify-content-center items-center'>
					<IC_EMPTY_CART />
				</div>
				<div className='d-flex justify-content-center items-center mt-20 bg-secondary100 p-10 rounded-6 px-30'>
					<div className={cls('text-grey900 fw-500', styles.empty_text)}>Your cart is empty</div>
				</div>
			</div>
		</div>
	);
};

export default MedicineCart;

export const MedicineCheckOutSummary = ({
	styles,
	isLogged,
	setShowCheckoutModal,
	setShowLoginModal,
	deliveryOption
}: any) => {
	const { carts, setShowAddressModal, getCart } = useCart();
	const alert = useAlert();
	const hasProducts = get(carts, 'products.length', 0) > 0;
	const hasPrescriptions = get(carts, 'prescriptions.length', 0) > 0;
	const hasAddressId = !!get(carts, 'uc_address.l_id');
	const hasProductsORPrescriptions = hasProducts || hasPrescriptions;
	const isPrescriptionRequired = get(carts, 'prescription_req', false);
	if (!hasProductsORPrescriptions) return null;
	const handelScroll = (e) => {
		e.stopPropagation();
		const element = document.getElementById('sticky-cart-payment-summary');
		if (element) {
			element.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'nearest'
			});
		}
	};

	const handleCheckout = () => {
		AroggaAPI.get(`${API_CART.CHECKOUT_INIT}?_delivery_option=${deliveryOption}`).then((res) => {
			if (res.status === 'success') {
				setShowCheckoutModal(true);
			} else {
				if (res?.message.includes('Coupon is expired')) {
					alert.warning({
						title: 'Warning',
						message: res?.message || 'Something went wrong',
						confirmText: 'Proceed',
						onConfirm: () => {
							setShowCheckoutModal(true);
						},
						cancelText: 'Cancel',
						onCancel: () => {
							getCart();
						}
					});
				}
			}
		});
	};

	return (
		<div className={styles.checkout_wraper}>
			<div className={styles.checkout}>
				<div className={styles.checkout__left}>
					<svg xmlns='http://www.w3.org/2000/svg' width='32' height='30' viewBox='0 0 32 30' fill='none'>
						<rect x='0.882812' width='31.0387' height='30' rx='5' fill='#0F8C89' />
						<mask
							id='mask0_885_73891'
							style={{ maskType: 'alpha' }}
							maskUnits='userSpaceOnUse'
							x='7'
							y='5'
							width='21'
							height='20'>
							<rect x='7.42188' y='5' width='19.6034' height='20' fill='#D9D9D9' />
						</mask>
						<g mask='url(#mask0_885_73891)'>
							<path
								d='M13.143 23.3332C12.6938 23.3332 12.3092 23.17 11.9893 22.8436C11.6694 22.5172 11.5094 22.1248 11.5094 21.6665C11.5094 21.2082 11.6694 20.8158 11.9893 20.4894C12.3092 20.163 12.6938 19.9998 13.143 19.9998C13.5923 19.9998 13.9769 20.163 14.2968 20.4894C14.6167 20.8158 14.7767 21.2082 14.7767 21.6665C14.7767 22.1248 14.6167 22.5172 14.2968 22.8436C13.9769 23.17 13.5923 23.3332 13.143 23.3332ZM21.3111 23.3332C20.8619 23.3332 20.4773 23.17 20.1574 22.8436C19.8375 22.5172 19.6775 22.1248 19.6775 21.6665C19.6775 21.2082 19.8375 20.8158 20.1574 20.4894C20.4773 20.163 20.8619 19.9998 21.3111 19.9998C21.7604 19.9998 22.1449 20.163 22.4649 20.4894C22.7848 20.8158 22.9447 21.2082 22.9447 21.6665C22.9447 22.1248 22.7848 22.5172 22.4649 22.8436C22.1449 23.17 21.7604 23.3332 21.3111 23.3332ZM13.143 19.1665C12.5304 19.1665 12.0676 18.8922 11.7545 18.3436C11.4414 17.795 11.4277 17.2498 11.7136 16.7082L12.8163 14.6665L9.8758 8.33317H9.03858C8.80715 8.33317 8.61656 8.25331 8.46681 8.09359C8.31706 7.93387 8.24219 7.73595 8.24219 7.49984C8.24219 7.26373 8.32046 7.06581 8.47702 6.90609C8.63357 6.74637 8.82757 6.6665 9.059 6.6665H10.3863C10.5361 6.6665 10.679 6.70817 10.8151 6.7915C10.9513 6.87484 11.0534 6.99289 11.1214 7.14567L11.6728 8.33317H23.7207C24.0883 8.33317 24.3401 8.47206 24.4763 8.74984C24.6124 9.02762 24.6056 9.31928 24.4558 9.62484L21.5562 14.9582C21.4064 15.2359 21.209 15.4512 20.964 15.604C20.7189 15.7568 20.4399 15.8332 20.1267 15.8332H14.0415L13.143 17.4998H22.1483C22.3798 17.4998 22.5704 17.5797 22.7201 17.7394C22.8699 17.8991 22.9447 18.0971 22.9447 18.3332C22.9447 18.5693 22.8665 18.7672 22.7099 18.9269C22.5534 19.0866 22.3594 19.1665 22.1279 19.1665H13.143Z'
								fill='white'
							/>
						</g>
					</svg>
					<div className={styles.checkout__total} onClick={handelScroll}>
						<div className='text-12 text-white fw-400 cursor-pointer'>{carts?.products?.length} items</div>
						<div className='text-white fw-600 text-12 cursor-pointer '>৳{carts?.po_payable_total}</div>
					</div>
				</div>
				<div
					onClick={() => {
						if (isLogged) {
							if (hasProductsORPrescriptions) {
								if (hasAddressId) {
									if (isPrescriptionRequired && !hasPrescriptions) {
										alert.error({
											title: 'Oops !',
											message: 'You may forgot to upload prescription ! Please upload your prescription'
										});
										return;
									}
									handleCheckout();
								} else {
									setShowAddressModal(true);
								}
							}
						} else {
							setShowLoginModal(true);
						}
					}}>
					<span className='text-14 fw-600 d-flex items-center text-white cursor-pointer mr-10'>
						Proceed to Checkout
						<svg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24' fill='none'>
							<mask
								id='mask0_885_73899'
								style={{ maskType: 'alpha' }}
								maskUnits='userSpaceOnUse'
								x='0'
								y='0'
								width='25'
								height='24'>
								<rect
									x='0.632812'
									y='24'
									width='24'
									height='23.5241'
									transform='rotate(-90 0.632812 24)'
									fill='#D9D9D9'
								/>
							</mask>
							<g mask='url(#mask0_885_73899)'>
								<path
									d='M14.8238 12.5248L11.2706 16.1498C11.2216 16.1998 11.1685 16.2373 11.1114 16.2623C11.0542 16.2873 10.9929 16.2998 10.9276 16.2998C10.7969 16.2998 10.6825 16.254 10.5845 16.1623C10.4865 16.0706 10.4375 15.9498 10.4375 15.7998L10.4375 8.1998C10.4375 8.0498 10.4865 7.92897 10.5845 7.8373C10.6825 7.74564 10.7969 7.6998 10.9276 7.6998C10.9603 7.6998 11.0746 7.7498 11.2706 7.8498L14.8238 11.4748C14.9054 11.5581 14.9626 11.6415 14.9953 11.7248C15.028 11.8081 15.0443 11.8998 15.0443 11.9998C15.0443 12.0998 15.028 12.1915 14.9953 12.2748C14.9626 12.3581 14.9054 12.4415 14.8238 12.5248Z'
									fill='white'
								/>
							</g>
						</svg>
					</span>
				</div>
			</div>
		</div>
	);
};

export const SelectDeliveryType = ({ de_options, setScheduleId, option, setOption }) => {
	const { data } = AroggaSWR(API_CART.GET_DELIVERY_SCHEDULE, 'GET', {});
	const { getCart } = useCart();
	const alert = useAlert();
	const [setSchedule, setSetSchedule] = useState(null) as any;
	const timeSlots = useMemo(() => transformSlots(data), [data]);
	if (!isObject(de_options)) {
		return null;
	}
	console.log('de_options', de_options);
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const deliveryOptionsElements = useMemo(
		() =>
			map(de_options, (item, key) => {
				const { text, amount, settable, error, info } = item;
				const isActive = option === key;
				const handleClick = () => {
					if (settable) {
						setOption(key);
						getCart({
							_delivery_option: key
						});
					} else {
						alert.error({
							title: 'Oops !',
							message: error
						});
					}
				};
				const currentSchedule = timeSlots.find((slot: any) => slot.value === setSchedule) || { label: '' };

				return (
					<>
						<div
							key={key}
							className={`${styles[key]} ${styles.common} ${!settable ? styles.disabled : ''}`}
							style={
								settable
									? { cursor: 'pointer' }
									: {
											cursor: 'not-allowed'
										}
							}
							onClick={handleClick}>
							<div className={styles.option_list}>
								<span className={isActive ? styles.active : ''}>
									{isActive ? <IC_CHECK /> : <MdRadioButtonUnchecked size={24} className='text-grey600' />}
								</span>
								<div className={styles.type}>
									<DeliveryType regular={key === 'regular'} maxWdt className='text-white' />
									{text && key === 'regular' && <p className={styles.text}>{text}</p>}
									{text && key !== 'regular' && (
										<p className={styles.text}>{currentSchedule?.label || text}</p>
									)}
								</div>
							</div>

							<>
								{!!amount ? (
									<p className='text-14 fw-500'>৳{amount}</p>
								) : (
									<>
										<p className='text-primary_base text-14 fw-500'>Free</p>
									</>
								)}
							</>
						</div>
						{error && (
							<p className='p-4 rounded-4 d-flex gap-6 w-full items-center'>
								<MdError
									style={{
										color: '#FD6A6A'
									}}
								/>
								<span
									className='text-error
										fw-500 text-10
										'>
									{error}
								</span>
							</p>
						)}
						{info && (
							<p className='bg-info p-4 rounded-4 d-flex gap-6 w-full items-center'>
								<MdInfo
									style={{
										color: '#2970FF'
									}}
								/>
								<span
									className='text-grey900 
										fw-500 text-10
										'>
									{info}
								</span>
							</p>
						)}
					</>
				);
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[de_options, option, setOption]
	);
	// eslint-disable-next-line react-hooks/rules-of-hooks

	const defaultValue = get(timeSlots, '[0].value');
	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		if (option === 'express') {
			setScheduleId(defaultValue);
		} else {
			setScheduleId(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [option]);

	return (
		<div className={styles.delivery_options}>
			{deliveryOptionsElements}
			{option === 'express' && (
				<div className={styles.select_date_time}>
					<Select
						ellipsis
						options={timeSlots}
						defaultValue={defaultValue}
						valueStyle={{
							color: '#0E7673',
							fontWeight: '500',
							fontSize: '12px'
						}}
						dropdownStyle={{
							fontSize: '12px'
						}}
						size='middle'
						onChange={(e) => {
							setScheduleId(e);
							setSetSchedule(e);
						}}
					/>
				</div>
			)}
		</div>
	);
};

import useCart from '@/contexts/CartProvider';
import useLabCart from '@labServices/context/CartContext';

import { getCheckoutData } from '@labServices/apis/lab-test-apis/cart';
import { isEmptyObject } from '@labServices/utils/cmnHelper';
import { v1_checkout_cart_image } from '@labServices/utils/svg';
import { MdArrowRight } from 'react-icons/md';

import styles from './LabTestCheckoutSummary.module.css';

const LabTestCheckoutSummary = ({
	isLogged,
	setLabCheckoutData,
	showCheckoutModal,
	handleOpenNotFound,
	setNotAvailableRes,
	setShowCheckoutModal,
	setShowLoginModal,
	setShowAddressModal
}) => {
	const { cartData } = useLabCart();
	const { carts: webCart } = useCart();

	const cartAddress = { ...webCart?.uc_address };

	const fetchCheckoutData = async (e) => {
		e.stopPropagation();

		let res;
		if (!isEmptyObject(cartAddress)) {
			res = await getCheckoutData();
			if (res?.success) {
				setLabCheckoutData(res); // set checkout data
				showCheckoutModal();
				return;
			}
		} else {
			setShowAddressModal(true);
			return;
		}

		setNotAvailableRes(res);
		handleOpenNotFound();
	};

	const handelScroll = (e) => {
		e.stopPropagation();
		if (typeof window !== 'undefined') {
			const element = document.getElementById('sticky-cart-payment-summary');
			if (element) {
				element.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'nearest'
				});
			}
		}
	};

	return (
		<>
			{cartData?.itemCount && (
				<div className={styles.checkout}>
					<div
						className={`${styles.cart_checkout} cursor_pointer`}
						onClick={(e) => {
							handelScroll(e);
							isLogged && fetchCheckoutData(e);
						}}>
						<div>
							{v1_checkout_cart_image}
							<div className=''>
								<p className='ltbody_md__medium'>{cartData?.itemCount} Items</p>
								<p className='ltbody_md__semibold'>
									৳
									{cartData?.isHardCopyRequired
										? parseInt(cartData?.totalAmount)
										: parseInt(cartData?.totalAmountWithoutConveyance)}
								</p>
							</div>
						</div>
						<div className={styles.cart_checkout_btn}>
							<p className='ltbody_md__semibold'>Proceed to Checkout</p>
							<MdArrowRight size={24} />
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default LabTestCheckoutSummary;

import cls from 'classnames';
import Image from 'next/image';
import { ReactNode, useState } from 'react';
import { MdCancel } from 'react-icons/md';
import styles from './styles/PrescriptionImage.module.scss';
interface PrescriptionImageProps {
	src: string;
	height?: number;
	width?: number;
	alt?: string;
	onClick?: () => void;
	size?: 'small' | 'medium' | 'large';
	label?: ReactNode;
	labelType?: 'success' | 'error' | 'warning' | 'info' | 'default';
	preview?: boolean;
}

const PrescriptionImage = ({
	src,
	height,
	width,
	alt,
	onClick,
	size,
	label,
	labelType,
	preview = false
}: PrescriptionImageProps) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<div className={cls(styles.container, styles[size])}>
			<Image
				unoptimized
				src={src}
				alt={alt}
				className={styles.image}
				onClick={() => preview && setIsModalOpen(true)}
				width={width || 500}
				height={height || 500}
			/>
			{label && (
				<div onClick={onClick} className={cls(styles.image_footer, styles[size], styles[labelType])}>
					{label}
				</div>
			)}
			{isModalOpen && (
				<div className={styles.modalOverlay}>
					<div className={styles.modal}>
						<Image
							unoptimized
							className={styles.fullImage}
							src={src}
							alt='Preview'
							width={width || 500}
							height={height || 500}
						/>
						<MdCancel className={styles.closeButton} size={24} onClick={() => setIsModalOpen(false)} />
					</div>
				</div>
			)}
		</div>
	);
};

export default PrescriptionImage;

import { MdDirectionsBike, MdRocketLaunch } from 'react-icons/md';
import styles from './delivery-type.module.scss';
import cls from 'classnames';

interface Props {
	regular: boolean;
	circleIcon?: boolean;
	text?: boolean;
	maxWdt?: boolean;
	className?: string;
}

const DeliveryType = ({ regular, circleIcon, text, maxWdt, className }: Props) => {
	return (
		<div
			className={cls(
				styles.delivery_type,
				text && styles.text,
				circleIcon && styles.circle_icon,
				maxWdt && styles.max_wdt,
				className
			)}
			style={{ maxWidth: maxWdt ? '150px' : 'auto' }}>
			{regular ? (
				<p>
					<MdDirectionsBike />
					{!circleIcon && 'Regular Delivery'}
				</p>
			) : (
				<p className={styles.express}>
					<MdRocketLaunch />
					{!circleIcon && 'Express Delivery'}
				</p>
			)}
			{text && <span>{regular ? 'Regular' : 'Express'} Delivery</span>}
		</div>
	);
};

export default DeliveryType;

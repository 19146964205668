'use client';
import { IC_ARROWDROPDOWN } from '@/assets/icons';
import AddressModal from '@/components/organisms/AddressModal';
import useAuth from '@/contexts/AuthProvider';
import useCart from '@/contexts/CartProvider';
import useLoginModel from '@/contexts/LoginModelProvider';
import { useState } from 'react';
import { MdLocationOn } from 'react-icons/md';

const DeliveryAddress = () => {
	const { isLogged, user } = useAuth();
	const { setShowLoginModal } = useLoginModel();
	const [showAddressModal, setShowAddressModal] = useState(false);
	const { carts } = useCart();

	const getDeliveryAddress = () => {
		if (isLogged) {
			if (!!carts?.po_user_location_id && carts.uc_address.ul_location) {
				return carts.uc_address.l_area + ', ' + carts.uc_address.l_district;
			}
			return 'Choose Location';
		} else {
			return 'Bangladesh';
		}
	};

	return (
		<div
			className={'top__left__delivery_warp'}
			onClick={() => {
				isLogged ? setShowAddressModal(true) : setShowLoginModal(true);
			}}>
			<div className={'top__left__delivery'}>
				<MdLocationOn size={24} className={'top__left__delivery__icon'} />

				<div className={'top__left__delivery__content'}>
					<div className={'top__left__delivery__location'}>
						Delivery to {isLogged && <span className='text-12'>{user?.u_name}</span>}
					</div>
					<div className={'top__left__delivery__location'}>{isLogged ? getDeliveryAddress() : 'Bangladesh'}</div>
				</div>
				<IC_ARROWDROPDOWN className={'top__left__delivery__icon_arrow'} />
			</div>
			{isLogged && showAddressModal && (
				<AddressModal isOpen={showAddressModal} setIsOpen={setShowAddressModal} hover={true} />
			)}
		</div>
	);
};

export default DeliveryAddress;

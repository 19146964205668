'use client';

import Button from '@/labtest/presentation/atoms/Button';
import Image from 'next/image';

import { IG_DoctorConsultationDetails } from '@/services/doc-consultation/apis/doc-consultation-apis/types/doc-consultation/type.getDocConsultationDetails';
import { useRouter } from 'next/navigation';
import { MdCall } from 'react-icons/md';

import styles from './IncomingCallCard.module.scss';

interface I_Props {
	messageCall: any;
	acceptCallInvitation: (messageCall: any) => void;
	handleCloseIncomingCallModal: () => void;
	declineCallInvitation: (messageCall: any) => void;
	setIsPatientResponding: (isPatientResponding: boolean) => void;
	audioRef?: any;
	patientId: string;
	data: IG_DoctorConsultationDetails;
}

const IncomingCallCard = ({
	messageCall,
	acceptCallInvitation,
	handleCloseIncomingCallModal,
	declineCallInvitation,
	setIsPatientResponding,
	// audioRef,
	patientId,
	data
}) => {
	const router = useRouter();

	const joinConferenceHandler = () => {
		handleCloseIncomingCallModal();
		// audioRef.current.pause();
		setIsPatientResponding(true);
		acceptCallInvitation(messageCall);

		setTimeout(() => {
			router.push(`/conference-room/video-call?role=patient&uid=${patientId}`);
		}, 1000);
	};

	const declineCallInvitationHandler = () => {
		// audioRef.current.pause();
		setIsPatientResponding(true);
		declineCallInvitation(messageCall);
		router.push('/');
	};

	return (
		<div className={styles.incomingCallCard}>
			<h4 className='lth4_bold primaryBase t_nowrap'>Doctor is calling you</h4>

			<div className={styles.incomingCallCard_body}>
				<div className={styles.incomingCallCard_image}>
					<Image
						src={
							data?.doctor?.profilePicture ||
							'https://png.pngtree.com/png-vector/20240205/ourlarge/pngtree-professional-doctor-with-stethoscope-png-image_11626578.png'
						}
						alt='Doctor'
						width={150}
						height={150}
						style={{
							width: '100%',
							height: '100%',
							borderRadius: '50%',
							objectFit: 'cover',
							objectPosition: 'center'
						}}
					/>
				</div>
				<div className={styles.incomingCallCard_info}>
					<h4 className='lth4_bold ltGreyscale900'>
						{data?.title?.en} {data?.doctor?.name?.en}
					</h4>
					<p className='ltbody_xlg__regular ltGreyscale700'>is Calling you</p>
				</div>
			</div>

			{/* <div className={styles.incomingCallCard_action}> */}
			{/* <Button iconLeft={<MdCallEnd size={24} />} variant={'errorDark'} size={'xs'} onClick={() => declineCallInvitationHandler()}> /> */}

			<Button
				iconLeft={<MdCall size={24} />}
				variant='bgPrimary'
				color='clWhite'
				size='lg'
				onClick={() => joinConferenceHandler()}>
				Answer
			</Button>
			{/* </div> */}
		</div>
	);
};

export default IncomingCallCard;
